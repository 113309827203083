import React from 'react';
import { motion } from 'framer-motion';
import '../../styles/About.css';

const About = () => {
  return (
    <div className="about-page">
      <motion.div 
        className="about-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1>About ForcaCapital.net</h1>
        <p>Trusted Analysis. Official Data. Clear Insights.</p>
      </motion.div>

      <motion.div 
        className="about-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <section className="about-section">
          <h2>Our Approach</h2>
          <p>
            ForcaCapital.net provides comprehensive fundamental outlooks for major currencies, 
            backed by official data from trusted sources. Our analysis integrates central bank 
            communications, economic indicators, and market trends to deliver accurate insights.
          </p>
          <p>
            We focus on presenting complex financial information in an accessible format,
            highlighting the key factors that influence currency values. Our platform is designed 
            to help both experienced traders and newcomers understand the fundamental drivers 
            behind currency movements.
          </p>
        </section>

        <section className="about-section">
          <h2>Our Commitment</h2>
          <p>
            <strong>Research Excellence:</strong> We maintain high standards of research integrity,
            ensuring our analysis is thorough, balanced, and based on comprehensive evaluation of 
            economic indicators and market conditions.
          </p>
          <p>
            <strong>Data-Driven Approach:</strong> Our analysis is based on verifiable data from 
            official sources including central banks, statistical agencies, and regulated
            financial institutions. We prioritize accuracy and reliability in our information.
          </p>
          <p>
            <strong>Transparency:</strong> We provide links to our data sources so you can verify information
            and dive deeper into topics that interest you. This commitment to transparency helps you 
            make informed decisions based on reliable data.
          </p>
        </section>

        <section className="about-section">
          <h2>Currencies Covered</h2>
          <div className="currency-grid">
            <div className="currency-card">
              <h3>EUR</h3>
              <p>Euro</p>
            </div>
            <div className="currency-card">
              <h3>USD</h3>
              <p>US Dollar</p>
            </div>
            <div className="currency-card">
              <h3>GBP</h3>
              <p>British Pound</p>
            </div>
            <div className="currency-card">
              <h3>JPY</h3>
              <p>Japanese Yen</p>
            </div>
            <div className="currency-card">
              <h3>CHF</h3>
              <p>Swiss Franc</p>
            </div>
            <div className="currency-card">
              <h3>CAD</h3>
              <p>Canadian Dollar</p>
            </div>
            <div className="currency-card">
              <h3>AUD</h3>
              <p>Australian Dollar</p>
            </div>
            <div className="currency-card">
              <h3>NZD</h3>
              <p>New Zealand Dollar</p>
            </div>
          </div>
        </section>

        <section className="about-section">
          <h2>Trusted Sources</h2>
          <p>
            We gather data exclusively from official and trusted sources including:
          </p>
          <ul className="sources-list">
            <li>European Central Bank (ECB)</li>
            <li>Federal Reserve (Fed)</li>
            <li>Bank of England (BoE)</li>
            <li>Bank of Japan (BoJ)</li>
            <li>Swiss National Bank (SNB)</li>
            <li>Bank of Canada (BoC)</li>
            <li>Reserve Bank of Australia (RBA)</li>
            <li>Reserve Bank of New Zealand (RBNZ)</li>
            <li>Eurostat</li>
            <li>US Bureau of Labor Statistics</li>
            <li>US Bureau of Economic Analysis</li>
            <li>UK Office for National Statistics</li>
            <li>Financial Times, Wall Street Journal, Bloomberg</li>
            <li>IMF and World Bank publications</li>
            <li>Central bank research papers and economic bulletins</li>
          </ul>
        </section>
        
        <section className="about-section">
          <h2>Our Analysis Framework</h2>
          <p>
            ForcaCapital.net employs a multi-faceted analytical framework that considers:
          </p>
          <ul className="analysis-list">
            <li><strong>Monetary Policy Trajectories:</strong> Central bank decisions and forward guidance</li>
            <li><strong>Economic Fundamentals:</strong> GDP, inflation, employment, and trade data</li>
            <li><strong>Sentiment Indicators:</strong> Purchasing manager indices and business confidence</li>
            <li><strong>Geopolitical Factors:</strong> International relations and trade agreements</li>
            <li><strong>Market Positioning:</strong> Institutional investor behavior and capital flows</li>
          </ul>
          <p>
            This comprehensive approach enables us to provide nuanced and forward-looking 
            currency outlooks that reflect both current realities and emerging trends.
          </p>
        </section>
      </motion.div>
    </div>
  );
};

export default About;