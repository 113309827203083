import React from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

const CurrencyTemplate = ({ currencyData }) => {
  const { symbol } = useParams();
  
  // Safely access data properties (with fallbacks for missing data)
  const fundamentalIndicators = currencyData?.["Fundamental Indicators"] || [];
  const recentSpeeches = currencyData?.["Recent Central Bank Speeches"] || [];
  const upcomingEvents = currencyData?.["Upcoming High-Impact Events"] || [];
  const impactFactors = currencyData?.["Top 3 Impact Factors"] || [];
  const outlook = currencyData?.Outlook || {};
  
  return (
    <div className="currency-page">
      <motion.div 
        className="currency-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1>{symbol}</h1>
        <h2>Currency Analysis</h2>
        <p className="rate-updated">Last updated: 31/03/2025</p>
      </motion.div>

      <motion.div 
        className="indicators-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <h2>Fundamental Indicators</h2>
        <table className="indicators-table">
          <thead>
            <tr>
              <th>Indicator</th>
              <th>Latest</th>
              <th>Previous</th>
              <th>Release Date</th>
              <th>Next Release</th>
              <th>Interpretation</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            {fundamentalIndicators.map((indicator, index) => (
              <tr key={index}>
                <td>{indicator.Indicator}</td>
                <td>{indicator.Latest}</td>
                <td>{indicator.Previous}</td>
                <td>{indicator["Release Date"]}</td>
                <td>{indicator["Next Release Date"]}</td>
                <td>{indicator.Interpretation}</td>
                <td>
                  <a href={indicator.Source} target="_blank" rel="noopener noreferrer">
                    Source
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </motion.div>

      <div className="currency-info-grid">
        <motion.div 
          className="speeches-section"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <h2>Recent Central Bank Speeches</h2>
          <ul className="speeches-list">
            {recentSpeeches.map((speech, index) => (
              <li key={index} className="speech-item">
                <h3>{speech.Speaker}</h3>
                <p className="speech-date">{speech.Date}</p>
                <p className="speech-summary">{speech.Summary}</p>
                <a href={speech.Source} target="_blank" rel="noopener noreferrer">
                  Full speech
                </a>
              </li>
            ))}
          </ul>
        </motion.div>

        <motion.div 
          className="events-section"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <h2>Upcoming High-Impact Events</h2>
          <ul className="events-list">
            {upcomingEvents.map((event, index) => (
              <li key={index} className="event-item">
                <h3>{event.Event}</h3>
                <p className="event-date">{event.Date}</p>
                <a href={event.Source} target="_blank" rel="noopener noreferrer">
                  More info
                </a>
              </li>
            ))}
          </ul>
        </motion.div>
      </div>

      <motion.div 
        className="impact-factors-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <h2>Top 3 Impact Factors</h2>
        <ol className="impact-factors-list">
          {impactFactors.map((factor, index) => (
            <li key={index} className="impact-factor-item">
              <strong>{factor.Factor}</strong>
              <p>{factor.Explanation}</p>
            </li>
          ))}
        </ol>
      </motion.div>

      <motion.div 
        className="outlook-summary-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
      >
        <h2>Outlook Summary</h2>
        <div className={`outlook-badge ${outlook.Direction?.toLowerCase()}`}>
          {outlook.Direction}
        </div>
        <div className="outlook-justification">
          <p>{outlook.Justification}</p>
        </div>
        <p className="outlook-disclaimer">
          This outlook is manually updated based on fundamental analysis.
        </p>
      </motion.div>
    </div>
  );
};

export default CurrencyTemplate;