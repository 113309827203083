import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import { 
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [fxRates, setFxRates] = useState({});
  const [volumeData, setVolumeData] = useState({});
  const [weeklyPerformanceData, setWeeklyPerformanceData] = useState({});
  const [performanceYear, setPerformanceYear] = useState(2025);
  const [volumeYear, setVolumeYear] = useState(2025);
  const [yearlyTotalPerformance, setYearlyTotalPerformance] = useState(0);
  const [yearlyTotalVolume, setYearlyTotalVolume] = useState(0);
  const [yearlyTotalLots, setYearlyTotalLots] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(true);
  
  // Currency outlooks data - updated weekly
  const currencyOutlooks = {
    'EUR': 'Bearish',
    'GBP': 'Bearish',
    'JPY': 'Bullish',
    'CAD': 'Bearish',
    'CHF': 'Bearish',
    'AUD': 'Bearish',
    'NZD': 'Bearish',
    'USD': 'Bearish'
  };
  
  // Check localStorage on mount to see if notification should be shown
  useEffect(() => {
    // Get value from localStorage or use 'first-visit' for first time visitors
    const hasVisitedBefore = localStorage.getItem('hasVisitedDashboard');
    
    // If they've visited before and closed the notification, don't show it
    if (hasVisitedBefore === 'true') {
      setShowNotification(false);
    } else {
      // Set that they've visited the dashboard
      localStorage.setItem('hasVisitedDashboard', 'true');
      setShowNotification(true);
    }
  }, []);
  
  // Load exchange rate data, weekly performance data, and trading volume data
  useEffect(() => {
    // In a real app, you'd fetch this from an API
    // For now, we'll use hard-coded data that matches our currency files
    const fetchData = async () => {
      try {
        // Fetch weekly performance data
        const performanceResponse = await fetch('/weeklyPerformance.json');
        const performanceData = await performanceResponse.json();
        
        // Fetch trading volume data
        const volumeResponse = await fetch('/tradingVolume.json');
        const volumeData = await volumeResponse.json();
        
        // Set the FX rates data
        setFxRates({
          'EUR/USD': 1.0832,
          'GBP/USD': 1.2621,
          'USD/JPY': 151.42,
          'USD/CAD': 1.3598,
          'USD/CHF': 0.8984,
          'AUD/USD': 0.6541,
          'NZD/USD': 0.5982,
        });
        
        // Process the performance data for the selected year
        const currentYearData = performanceData.performance.find(
          (item) => item.year === performanceYear
        );
        
        if (currentYearData) {
          // Calculate total yearly performance
          const totalPerformance = currentYearData.weeks.reduce(
            (acc, week) => acc + week.performance,
            0
          );
          setYearlyTotalPerformance(parseFloat(totalPerformance.toFixed(2)));
          
          setWeeklyPerformanceData({
            labels: currentYearData.weeks.map((week) => `Week ${week.week}`),
            datasets: [
              {
                label: `${performanceYear} Weekly Performance (%)`,
                data: currentYearData.weeks.map((week) => week.performance),
                backgroundColor: currentYearData.weeks.map((week) => 
                  week.performance >= 0 ? 'rgba(0, 210, 91, 0.7)' : 'rgba(255, 45, 85, 0.7)'
                ),
                borderColor: currentYearData.weeks.map((week) => 
                  week.performance >= 0 ? 'rgba(0, 210, 91, 1)' : 'rgba(255, 45, 85, 1)'
                ),
                borderWidth: 1,
                borderRadius: 5,
              }
            ]
          });
        }
        
        // Process the volume data for the selected year
        const currentVolumeYearData = volumeData.volume.find(
          (item) => item.year === volumeYear
        );
        
        if (currentVolumeYearData) {
          // Calculate yearly totals
          const totalVolume = currentVolumeYearData.weeks.reduce(
            (acc, week) => acc + week.volume,
            0
          );
          const totalLots = currentVolumeYearData.weeks.reduce(
            (acc, week) => acc + week.lots,
            0
          );
          
          setYearlyTotalVolume(totalVolume);
          setYearlyTotalLots(totalLots);
          
          setVolumeData({
            labels: currentVolumeYearData.weeks.map((week) => `Week ${week.week}`),
            datasets: [
              {
                label: 'Trading Volume (USD)',
                data: currentVolumeYearData.weeks.map((week) => week.volume / 1000000), // Convert to millions
                borderColor: '#06189c',
                backgroundColor: 'rgba(6, 24, 156, 0.2)',
                tension: 0.4,
                borderWidth: 2,
                pointBackgroundColor: '#06189c',
                pointBorderColor: '#ffffff',
                pointBorderWidth: 1,
                pointRadius: 4,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#06189c',
                pointHoverBorderColor: '#ffffff',
                fill: true,
                yAxisID: 'y',
              },
              {
                label: 'Trading Lots',
                data: currentVolumeYearData.weeks.map((week) => week.lots),
                borderColor: '#0a2be0',
                backgroundColor: 'rgba(73, 100, 255, 0.2)',
                tension: 0.4,
                borderWidth: 2,
                pointBackgroundColor: '#0a2be0',
                pointBorderColor: '#ffffff',
                pointBorderWidth: 1,
                pointRadius: 4,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#0a2be0',
                pointHoverBorderColor: '#ffffff',
                fill: false,
                yAxisID: 'y1',
              }
            ]
          });
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, [performanceYear, volumeYear]);

  // Handle year change for performance chart
  const handleYearChange = (year) => {
    setPerformanceYear(year);
  };
  
  // Handle year change for volume chart
  const handleVolumeYearChange = (year) => {
    setVolumeYear(year);
  };
  
  // Chart options for volume chart
  const volumeChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'rgba(255, 255, 255, 0.8)',
          font: {
            family: 'Inter',
            size: 12,
            weight: 300
          },
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      title: {
        display: true,
        text: 'Weekly Trading Volume',
        color: '#fff',
        font: {
          family: 'Inter',
          size: 16,
          weight: 300
        },
        padding: {
          bottom: 20
        }
      },
      tooltip: {
        backgroundColor: 'rgba(30, 30, 30, 0.8)',
        titleColor: '#fff',
        bodyColor: 'rgba(255, 255, 255, 0.8)',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        padding: 12,
        cornerRadius: 8,
        titleFont: {
          family: 'Inter',
          weight: 500
        },
        bodyFont: {
          family: 'Inter',
          weight: 300
        },
        displayColors: true,
        boxPadding: 5,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              if (context.dataset.label === 'Trading Volume (USD)') {
                label += '$' + context.parsed.y.toFixed(1) + 'M';
              } else {
                label += context.parsed.y;
              }
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Volume (USD in millions)',
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: 'Inter',
            weight: 300,
            size: 12
          }
        },
        beginAtZero: true,
        suggestedMax: 2.5, // Added this line to provide more breathing space
        ticks: { 
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: 'Inter',
            weight: 300,
            size: 11
          },
          padding: 10,
          stepSize: 0.5, // Added this line to show more tick marks
          callback: function(value) {
            return '$' + value + 'M';
          }
        },
        grid: { 
          color: 'rgba(255, 255, 255, 0.05)',
          drawBorder: false
        },
        border: {
          display: false
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Lots',
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: 'Inter',
            weight: 300,
            size: 12
          }
        },
        beginAtZero: true,
        suggestedMax: 25, // Added this line to provide more breathing space for lots
        ticks: { 
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: 'Inter',
            weight: 300,
            size: 11
          },
          padding: 10,
          stepSize: 5  // Added this line to show tick marks at intervals of 5
        },
        grid: {
          drawOnChartArea: false,
          color: 'rgba(255, 255, 255, 0.05)',
          drawBorder: false
        },
        border: {
          display: false
        }
      },
      x: {
        ticks: { 
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: 'Inter',
            weight: 300,
            size: 11
          },
          padding: 10,
          maxRotation: 45,
          minRotation: 45
        },
        grid: { 
          color: 'rgba(255, 255, 255, 0.05)',
          drawBorder: false,
          display: false
        },
        border: {
          display: false
        }
      }
    },
    animation: {
      duration: 2000,
      easing: 'easeOutQuart'
    },
    elements: {
      line: {
        tension: 0.4
      }
    },
    interaction: {
      mode: 'index',
      intersect: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  };
  
  // Chart options for weekly performance chart
  const performanceChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Weekly Performance (%)',
        color: '#fff',
        font: {
          family: 'Inter',
          size: 16,
          weight: 300
        },
        padding: {
          bottom: 20
        }
      },
      tooltip: {
        backgroundColor: 'rgba(30, 30, 30, 0.8)',
        titleColor: '#fff',
        bodyColor: 'rgba(255, 255, 255, 0.8)',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        padding: 12,
        cornerRadius: 8,
        titleFont: {
          family: 'Inter',
          weight: 500
        },
        bodyFont: {
          family: 'Inter',
          weight: 300
        },
        displayColors: true,
        boxPadding: 5,
        callbacks: {
          label: function(context) {
            let value = Number(context.raw).toFixed(2);
            return value >= 0 ? `+${value}%` : `${value}%`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: false,
        ticks: { 
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: 'Inter',
            weight: 300,
            size: 11
          },
          padding: 10,
          callback: function(value) {
            return value >= 0 ? `+${Number(value).toFixed(2)}%` : `${Number(value).toFixed(2)}%`;
          }
        },
        grid: { 
          color: 'rgba(255, 255, 255, 0.05)',
          drawBorder: false
        },
        border: {
          display: false
        }
      },
      x: {
        ticks: { 
          color: 'rgba(255, 255, 255, 0.7)',
          font: {
            family: 'Inter',
            weight: 300,
            size: 11
          },
          padding: 10,
          maxRotation: 45,
          minRotation: 45
        },
        grid: { 
          color: 'rgba(255, 255, 255, 0.05)',
          drawBorder: false,
          display: false
        },
        border: {
          display: false
        }
      }
    },
    animation: {
      duration: 2000,
      easing: 'easeOutQuart'
    },
    interaction: {
      mode: 'index',
      intersect: false
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  };

  return (
    <div className="dashboard">
      <AnimatePresence>
        {showNotification && (
          <motion.div 
            className="notification-popup"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          >
            <div className="notification-content">
              <h3>Data Update Notice</h3>
              <p>The dashboard currently displays sample data. The fundamental section with outlooks is up to date and gets updated every Monday.</p>
              <button 
                className="close-notification" 
                onClick={() => {
                  setShowNotification(false);
                }}
              >
                ✕
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      
      <motion.div 
        className="dashboard-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1>FX Dashboard</h1>
        <p>Live exchange rates and trading volume</p>
      </motion.div>

      {loading ? (
        <div className="loading">Loading dashboard data...</div>
      ) : (
        <>
          <motion.div 
            className="fx-rates-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <h2>Live Exchange Rates</h2>
            <div className="fx-rates-grid">
              {Object.entries(fxRates).map(([pair, rate], index) => (
                <motion.div 
                  key={pair}
                  className="fx-rate-card"
                  whileHover={{ scale: 1.03 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index, duration: 0.3 }}
                >
                  <h3>{pair}</h3>
                  <p className="rate">{rate.toFixed(4)}</p>
                  <Link to={`/currency/${pair.split('/')[0]}`}>
                    View {pair.split('/')[0]} analysis
                  </Link>
                </motion.div>
              ))}
            </div>
          </motion.div>

          <motion.div 
            className="currency-outlooks-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            <h2>Currency Outlooks</h2>
            <p className="outlook-update-info">Updated weekly every Monday</p>
            <div className="outlooks-grid">
              {Object.entries(currencyOutlooks).map(([currency, direction], index) => (
                <motion.div 
                  key={currency}
                  className="outlook-card"
                  whileHover={{ scale: 1.03 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index, duration: 0.3 }}
                >
                  <h3>{currency}</h3>
                  <div className={`outlook-indicator ${direction.toLowerCase()}`}>
                    {direction}
                  </div>
                  <Link to={`/currency/${currency}`}>
                    View analysis
                  </Link>
                </motion.div>
              ))}
            </div>
          </motion.div>

          <motion.div 
            className="performance-chart-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <h2>Weekly Performance</h2>
            <div className="year-selector">
              <button 
                className={performanceYear === 2024 ? 'active' : ''} 
                onClick={() => handleYearChange(2024)}
              >
                2024
              </button>
              <button 
                className={performanceYear === 2025 ? 'active' : ''} 
                onClick={() => handleYearChange(2025)}
              >
                2025
              </button>
            </div>
            <div className="chart-wrapper">
              <Bar data={weeklyPerformanceData} options={performanceChartOptions} />
            </div>
            <div className="performance-summary">
              <div className="total-performance">
                <span className="label">Total {performanceYear} Performance:</span>
                <span className={`value ${yearlyTotalPerformance >= 0 ? 'positive' : 'negative'}`}>
                  {yearlyTotalPerformance >= 0 ? '+' : ''}{yearlyTotalPerformance}%
                </span>
              </div>
            </div>
          </motion.div>

          <motion.div 
            className="volume-chart-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <h2>Trading Volume</h2>
            <div className="year-selector">
              <button 
                className={volumeYear === 2024 ? 'active' : ''} 
                onClick={() => handleVolumeYearChange(2024)}
              >
                2024
              </button>
              <button 
                className={volumeYear === 2025 ? 'active' : ''} 
                onClick={() => handleVolumeYearChange(2025)}
              >
                2025
              </button>
            </div>
            <div className="chart-wrapper">
              <Line data={volumeData} options={volumeChartOptions} />
            </div>
            <div className="volume-summary">
              <div className="volume-metrics">
                <div className="volume-metric">
                  <span className="label">Total Volume:</span>
                  <span className="value">
                    ${(yearlyTotalVolume / 1000000).toFixed(1)}M
                  </span>
                </div>
                <div className="volume-metric">
                  <span className="label">Total Lots:</span>
                  <span className="value">
                    {yearlyTotalLots.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default Dashboard;