import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useState } from 'react';
import Dashboard from './components/dashboard/Dashboard';
import createCurrencyComponent from './components/currencies/createCurrencyComponent';
import About from './components/about/About';
import './App.css';
import './styles/Dashboard.css';
import './styles/Currency.css';
import './styles/About.css';

// Create currency components using the factory function
const EUR = createCurrencyComponent('EUR');
const USD = createCurrencyComponent('USD');
const GBP = createCurrencyComponent('GBP');
const JPY = createCurrencyComponent('JPY');
const CHF = createCurrencyComponent('CHF');
const CAD = createCurrencyComponent('CAD');
const AUD = createCurrencyComponent('AUD');
const NZD = createCurrencyComponent('NZD');


function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <Router>
      <motion.div 
        className="App"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <nav className="navbar">
          <div className="nav-container">
            <motion.div 
              className="logo-container"
              whileHover={{ scale: 1.05 }}
            >
              <Link to="/" onClick={closeMobileMenu}>
                <h1>ForcaCapital.net</h1>
              </Link>
            </motion.div>
            
            <div className="hamburger" onClick={toggleMobileMenu}>
              <span className={mobileMenuOpen ? 'open' : ''}></span>
              <span className={mobileMenuOpen ? 'open' : ''}></span>
              <span className={mobileMenuOpen ? 'open' : ''}></span>
            </div>
            
            <ul className={`nav-links ${mobileMenuOpen ? 'active' : ''}`}>
              <li><Link to="/" onClick={closeMobileMenu}>Dashboard</Link></li>
              <li>
                <div className="dropdown">
                  <span>Currencies</span>
                  <div className="dropdown-content">
                    <Link to="/currency/EUR" onClick={closeMobileMenu}>EUR</Link>
                    <Link to="/currency/USD" onClick={closeMobileMenu}>USD</Link>
                    <Link to="/currency/GBP" onClick={closeMobileMenu}>GBP</Link>
                    <Link to="/currency/JPY" onClick={closeMobileMenu}>JPY</Link>
                    <Link to="/currency/CHF" onClick={closeMobileMenu}>CHF</Link>
                    <Link to="/currency/CAD" onClick={closeMobileMenu}>CAD</Link>
                    <Link to="/currency/AUD" onClick={closeMobileMenu}>AUD</Link>
                    <Link to="/currency/NZD" onClick={closeMobileMenu}>NZD</Link>
                  </div>
                </div>
              </li>
              <li><Link to="/about" onClick={closeMobileMenu}>About</Link></li>
            </ul>
          </div>
        </nav>
        
        <main>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/currency/EUR" element={<EUR />} />
            <Route path="/currency/USD" element={<USD />} />
            <Route path="/currency/GBP" element={<GBP />} />
            <Route path="/currency/JPY" element={<JPY />} />
            <Route path="/currency/CHF" element={<CHF />} />
            <Route path="/currency/CAD" element={<CAD />} />
            <Route path="/currency/AUD" element={<AUD />} />
            <Route path="/currency/NZD" element={<NZD />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </main>
        
        <footer className="footer">
          <div className="footer-content">
            <p>&copy; {new Date().getFullYear()} ForcaCapital.net — All analysis is manually written</p>
          </div>
        </footer>
      </motion.div>
    </Router>
  );
}

export default App;