import React, { useState, useEffect } from 'react';
import CurrencyTemplate from './CurrencyTemplate';
import '../../styles/Currency.css';

/**
 * Factory function to create currency components
 * @param {string} currencyCode - The currency code (e.g., 'EUR', 'USD')
 * @returns {React.Component} - A component that loads data for that currency
 */
const createCurrencyComponent = (currencyCode) => {
  // Create a component with the currency code as its name
  const CurrencyComponent = () => {
    const [currencyData, setCurrencyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
      // Load data from the corresponding JSON file
      fetch(`/${currencyCode.toLowerCase()}data.json`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to load ${currencyCode} data`);
          }
          return response.json();
        })
        .then(data => {
          setCurrencyData(data);
          setLoading(false);
        })
        .catch(err => {
          console.error(`Error loading ${currencyCode} data:`, err);
          setError(`Error loading currency data: ${err.message}`);
          setLoading(false);
        });
    }, []);
  
    if (loading) {
      return <div className="loading">Loading currency data...</div>;
    }
  
    if (error) {
      return <div className="error-message">{error}</div>;
    }
  
    return <CurrencyTemplate currencyData={currencyData} />;
  };

  // Set the display name for debugging purposes
  CurrencyComponent.displayName = currencyCode;
  
  return CurrencyComponent;
};

export default createCurrencyComponent;